export const playbookRunsPageTabs = [
  {
    key: "Playbooks",
    label: "Playbooks",
    path: "/playbooks"
  },
  {
    key: "Runs",
    label: "Runs",
    path: "/playbooks/runs"
  }
];
